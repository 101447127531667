var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, persistent: "", width: "800" },
      on: {
        "click:outside": function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pl-5 pr-2 pt-4 pb-2" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: { color: _vm.duplicates ? "error" : "warning" }
                },
                [_vm._v("mdi-alert")]
              ),
              _c("div", { staticClass: "title h6" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.duplicates
                        ? "Duplicate Promotion(s)"
                        : "Overlapping Promotion(s)"
                    ) +
                    " "
                )
              ]),
              _c("v-card-subtitle", { staticClass: "pa-0 ma-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.duplicates
                        ? "The following duplicate promotions were detected. Please review and adjust this promotion to proceed."
                        : "The following overlapping promotions were detected. Continue?"
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c("v-divider", { staticClass: "mx-4" }),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                staticClass: "mx-4",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.duplicates
                    ? _vm.duplicatePromos
                    : _vm.overlappingPromos,
                  "no-data-text": _vm.duplicates
                    ? "No duplicate promotions"
                    : "No overlapping promotions",
                  dense: "",
                  height: _vm.tableHeight,
                  "fixed-header": "",
                  "disable-pagination": "",
                  "footer-props": { "items-per-page-options": [-1] },
                  "items-per-page": -1
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.promo_id",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "router-link",
                            {
                              staticClass: "primary--text",
                              staticStyle: { "text-decoration": "none" },
                              attrs: {
                                to: { path: "/promo?id=" + item.promo_id },
                                target: "_blank"
                              }
                            },
                            [_vm._v(" " + _vm._s(item.promo_id) + " ")]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.start_dt",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.end_dt
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$config.formatDate(item.start_dt))
                                )
                              ])
                            : _c("span", [_vm._v("-")])
                        ]
                      }
                    },
                    {
                      key: "item.end_dt",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          item.end_dt
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$config.formatDate(item.end_dt))
                                )
                              ])
                            : _c("span", [_vm._v("-")])
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _vm.overlap && !_vm.duplicates
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-4 mr-4",
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              return _vm.close()
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    : _vm._e(),
                  _vm.overlap && !_vm.duplicates
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-4",
                          attrs: { color: "warning" },
                          on: {
                            click: function($event) {
                              return _vm.submitWithOverlap()
                            }
                          }
                        },
                        [_vm._v(" Submit ")]
                      )
                    : _vm._e(),
                  _vm.duplicates
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mb-4",
                          attrs: { color: "warning" },
                          on: {
                            click: function($event) {
                              return _vm.close()
                            }
                          }
                        },
                        [_vm._v(" Acknowledge ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }