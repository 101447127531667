<template>
  <v-dialog :value="value" persistent width="800" @click:outside="close()">
    <v-card>
      <v-card-title class="pl-5 pr-2 pt-4 pb-2">
        <v-icon class="mr-2" :color="duplicates ? 'error' : 'warning'">mdi-alert</v-icon>
        <div class="title h6">
          {{ duplicates ? 'Duplicate Promotion(s)' : 'Overlapping Promotion(s)' }}
        </div>
        <v-card-subtitle class="pa-0 ma-0">
          {{ duplicates ? 'The following duplicate promotions were detected. Please review and adjust this promotion to proceed.' : 'The following overlapping promotions were detected. Continue?' }} 
        </v-card-subtitle>
      </v-card-title>
      <v-divider class="mx-4"/>
      <v-container fluid class="pa-0">
        <v-data-table
          class="mx-4"
          :headers="headers"
          :items="duplicates ? duplicatePromos : overlappingPromos"
          :no-data-text="duplicates ? 'No duplicate promotions' : 'No overlapping promotions'"
          dense
          :height="tableHeight"
          fixed-header
          disable-pagination
          :footer-props="{'items-per-page-options': [-1]}"
          :items-per-page="-1">
          <template v-slot:[`item.promo_id`]="{ item }">
            <router-link class="primary--text" style="text-decoration:none;" :to="{ path: `/promo?id=${item.promo_id}`}" target="_blank">
              {{item.promo_id}}
            </router-link>
          </template>
          <template v-slot:[`item.start_dt`]="{ item }">
            <span v-if="item.end_dt">{{$config.formatDate(item.start_dt)}}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:[`item.end_dt`]="{ item }">
            <span v-if="item.end_dt">{{$config.formatDate(item.end_dt)}}</span>
            <span v-else>-</span>
          </template>
        </v-data-table>
        <div class="d-flex justify-center">
          <v-btn v-if="overlap && !duplicates" class="mb-4 mr-4" text @click="close()">
            Cancel
          </v-btn>
          <v-btn v-if="overlap && !duplicates" class="mb-4" color="warning" @click="submitWithOverlap()">
            Submit
          </v-btn>
          <v-btn v-if="duplicates" class="mb-4" color="warning" @click="close()">
            Acknowledge
          </v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { utils } from '@/mixins/utils'
import { displayAlert } from '@/mixins/alert'
export default {
  name: 'PromoConflictModal',
  mixins: [displayAlert, utils],
  props: [
    'value',
    'overlappingPromos',
    'duplicatePromos'
  ],
  data() {
    return {
      headerProps: {
        sortable: false,
        class: 'accent white--text'
      },
      baseHeaders: [
        { text: 'Promo ID', value: 'promo_id' },
        { text: 'Promo Name', value: 'promo_name' },
        { text: 'Promo Number', value: 'promo_number' },
        { text: 'Promo Category', value: 'promo_category' },
        { text: 'Start Date', value: 'start_dt' },
        { text: 'End Date', value: 'end_dt' }
      ]
    }
  },
  computed: {
    headers () {
      return this.baseHeaders.map(header => {
        return { ...this.headerProps, ...header }
      })
    },
    tableHeight () {
      return this.overlappingPromos?.length >= 12 || this.duplicatePromos?.length >= 12 ? '384px' : undefined
    },
    overlap () {
      return this.overlappingPromos?.length > 0
    },
    duplicates () {
      return this.duplicatePromos?.length > 0
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submitWithOverlap () {
      this.$emit('submitWithOverlap')
    }
  }
}
</script>
<style>
.header {
  color: white;
  background: #385F73;
}
.title {
  width: 73%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
  
